<template>
  <div class="scale-box">
    <div class="scale-box-statistics">
      挖掘硬件机报警（条）: <span class="scale-box-statistics-num">{{ options.sumCount || '--' }}</span>
    </div>
    <div class="scale-box-charts" ref="service-bar">
      <Chart style="height: 100%" ref="lineBox" :option="pieOptions" />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  data() {
    return {
      pieOptions: {}
    }
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.initCharts(v.dataList || []);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.init()
  },
  methods: {
   init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
         this.$nextTick(() => {
            // this.initCharts();
         });
      // })
   },
    getSize(res, type) {
      const clientWidth = this.$refs['service-bar'].offsetWidth;
      const clientHeight = this.$refs['service-bar'].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(data = []) {
      var scale = this.getSize(0.3, 2);
      var echartData = data.map(val=>({
         name: val.alarmName,
         value: val.acRatio,
      }));
      var rich = {
        yellow: {
          color: "#ffc72b",
          fontSize: scale * 2.6,
          fontFamily: 'ZhanKuQingKeHuangYouTi',
          align: 'center'
        },
        total: {
          color: "#ffc72b",
          fontSize: scale * 2.2,
          align: 'center'
        },
        white: {
          color: "#fff",
          align: 'center',
          fontSize: scale * 2.2,
          padding: [1, 0]
        },
        blue: {
          color: '#49dff0',
          fontSize: 2.2 * scale,
          align: 'center'
        },
        hr: {
         //  borderColor: '#0b5263',
          backgroundColor: 't',
            borderRadius: 3,
            width: 2,
            height: 2,
            padding: [4, 4, 0, -12]
        }
      };
      this.pieOptions = {
        legend: {
          top: "center",
          type: 'scroll',
          width: "10%",
          textStyle: {
            color: "#fff",
            fontSize: scale * 2.2,
          },
          "orient": "vertical",
          itemWidth: scale * 1.2,
          itemHeight: scale * 1.2,
          right: scale * 1.2,
          textStyle: {
            rich: {
              name: {
                width: scale * 2.2,
                color: "#fff",
              },
            },
          },
          formatter: function (params) {
            return `{name|${params}}`;
          },
          data: echartData.map(val=> val.name),
        },
        series: [{
          name: '',
          type: 'pie',
		      center: ["35%", "50%"],
          radius: ['35%', '60%'],
          color: ['#116ec9', '#770bd8', '#d0c512', '#1caddb', '#128aff', '#6f81da', '#00ffb4'],
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'outside',
                color: '#ddd',
                formatter: function (params, ticket, callback) {
                  return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '% }';
                },
                rich: rich
              },
              labelLine: {
                show: true,
                type: 'dashed',
                length: scale * 2,
                length2: scale * 3,
                color: '#00ffff'
              }
            }
          },
          data: echartData
        }]
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.scale-box {
  padding: 4%;
  height: 88%;
  &-statistics {
    height: 0.24rem;
    line-height: 0.24rem;
    padding: 0 0.16rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
     background-image: url('../../../../assets/images/bigScreen/newMechanical/alarmHear.png');
    font-size: 0.14rem;
    color: #FFFFFF;
    &-num {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.16rem;
      margin-left: 0.1rem;
      color: #FFFD37;
    }
  }
  &-charts {
    height: calc(100% - 0.24rem);
  }
}
</style>