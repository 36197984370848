<template>
  <div class="scale-box" ref="distributionBox">
     <!-- :style="{ width: itemWidth + 'px', height: itemWidth + 'px' }" -->
    <div ref="service-bar">
        <header-name title="机械品牌分布" />
        <Chart style="height: calc(100% - 0.24rem)" ref="lineBox" :option="brandPieOptions" />
    </div>
    <div>
        <header-name title="金融占比" />
        <Chart style="height: calc(100% - 0.24rem)" ref="lineBox" :option="financePieOptions" />
    </div>
    <div>
        <header-name title="机械年龄分布" />
        <Chart style="height: calc(100% - 0.24rem)" ref="lineBox" :option="agePieOptions" />
    </div>
    <div>
        <header-name title="机械价值和保费" />
        <div class="scheme-box" style="height: calc(100% - 0.24rem)">
          <div class="scheme-box-item scheme-box-item-head">
            <div class="scheme-box-item-flex">设备类型</div>
            <div class="scheme-box-item-flex">平均价值</div>
            <div class="scheme-box-item-right">单均保费</div>
          </div>
          <div class="scheme-box-content" ref="model-box">
            <vue-seamless-scroll :data="options.mechanicalValuePremiumAmountList"
              :style="{ width: '100%',height: boxH+'px', overflow: 'hidden' }"
              :class-option="defaultOption">
              <div class="scheme-box-item" v-for="item,index in options.mechanicalValuePremiumAmountList" :key="index">
                <div class="scheme-box-item-flex">{{ item.deviceType || '--' }}</div>
                <div class="scheme-box-item-flex">{{ item.averageVale || '--' }}</div>
                <div class="scheme-box-item-right">{{ item.singleAveragePremiumAmount || '--' }}</div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
    </div>
    <!-- <div class="scale-box-charts" ref="service-bar">
      <Chart style="height: 100%" ref="lineBox" :option="pieOptions" />
    </div> -->
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import HeaderName from "@/views/BigScreenDisplay/components/HeaderName";
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  components: { Chart, HeaderName, vueSeamlessScroll },
  props: {
    options: {
      type: Object,
      default: ()=>{}
    }
  },
  data() {
    return {
      brandPieOptions: {},
      financePieOptions: {},
      agePieOptions: {},
      itemWidth: 0,
      boxH: 0,
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      situationoptions: [
        {
          aa: 2
        },
        {
          aa: 2
        },
        {
          aa: 2
        },
        {
          aa: 2
        },
        {
          aa: 2
        },
        {
          aa: 2
        },
      ]
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.initCharts(v.brand || [], 'brandPieOptions');
          this.initCharts(v.finance || [], 'financePieOptions');
          this.initCharts(v.age || [], 'agePieOptions');
        });
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.init()
  },
  methods: {
   init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
         this.$nextTick(() => {
            const clientWidth = this.$refs['distributionBox'].offsetWidth / 2;
            const clientHeight = this.$refs['distributionBox'].offsetHeight / 2;
            this.itemWidth = clientWidth > clientHeight ? clientHeight : clientWidth;
            this.boxH = this.$refs["model-box"].offsetHeight;
         });
      // })
   },
    getSize(res, type) {
      const clientWidth = this.$refs['service-bar'].offsetWidth;
      const clientHeight = this.$refs['service-bar'].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(data = [], chartsName) {
      var scale = this.getSize(0.3, 2);
      var echartData = data.map(val=>({
         name: val.name,
         percent: val.proportion,
         value: val.tonOrPremiumAmount,
      }));
      var rich = {
        yellow: {
          color: "#ffc72b",
          fontSize: scale * 2.6,
          fontFamily: 'ZhanKuQingKeHuangYouTi',
          align: 'center'
        },
        total: {
          color: "#ffc72b",
          fontSize: scale * 2.2,
          align: 'center'
        },
        white: {
          color: "#fff",
          align: 'center',
          fontSize: scale * 2.2,
          padding: [1, 0]
        },
        blue: {
          color: '#49dff0',
          fontSize: 2.2 * scale,
          align: 'center'
        },
        hr: {
         //  borderColor: '#0b5263',
          backgroundColor: 't',
            borderRadius: 3,
            width: 2,
            height: 2,
            padding: [4, 4, 0, -12]
        }
      };
      this[chartsName] = {
        series: [{
          name: '',
          type: 'pie',
          radius: ['0%', '50%'],
          color: ['#116ec9', '#770bd8', '#d0c512', '#1caddb', '#128aff', '#6f81da', '#00ffb4'],
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'outside',
                color: '#ddd',
                formatter: function (params, ticket, callback) {
                  return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '/' + params.percent + '% }';
                },
                rich: rich
              },
              labelLine: {
                show: true,
                type: 'dashed',
                length: scale * 2,
                length2: scale * 3,
                color: '#00ffff'
              }
            }
          },
          data: echartData
        }]
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.scale-box {
  padding: 0.5% 2%;
  height: 90.5%;
  display: flex;
  flex-wrap: wrap;
  >div {
    width: 50%;
    height: 50%;
    padding: 3%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
     background-image: url('../../../../assets/images/bigScreen/newMechanical/map.png');
  }
  
.scheme-box {
  padding: 1%;
  // height: 82%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  &-content {
    flex: 1;
  }
  &-item {
    display: flex;
    height: 0.33rem;
    line-height: 0.33rem;
    font-size: 0.14rem;
    color: #9EC7FF;
    // margin: 0 0.15rem;
    padding-left: 0.12rem;
    &-flex {
      flex: 1;
    }
    &-right {
      flex: 0.9;
      // text-align: center;
    }
    &-head {
      height: 0.4rem;
      line-height: 0.4rem;
      font-weight: 500;
      font-size: 0.14rem;
      color: #20CEFA;
    }
  }
}
.scheme-box-item:nth-child(even) {
    background: rgba(18,39,153,0.3);
}
}
</style>