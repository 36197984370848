<template>
  <div class="scheme-box" ref="model-box">
    <vue-seamless-scroll
      :data="options"
      :style="{ width: '100%', height: '96%', overflow: 'hidden' }"
      :class-option="defaultOption"
    >
      <div
        class="situation-box"
        v-for="(item, index) in options"
        :key="index"
      >
        <div class="situation-box-left">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.name"
            placement="top"
          >
            <div class="situation-box-left">{{ item.name || '--' }}</div>
          </el-tooltip>
        </div>
        <div class="situation-box-progress">
          <el-tooltip
            class="item"
            popper-class="supplier-progress-item"
            effect="dark"
            :content="'预计赔付：' + item.estimateAmount + '万'"
            placement="top"
          >
            <div slot="content">
              <div class="supplier-progress-tooltip">
                <div class="supplier-progress-tooltip-left">{{ item.name || '--' }}</div>
                <div class="supplier-progress-tooltip-right">{{ item.remark || '--' }}</div>
              </div>
              <div class="supplier-progress-tooltip2">
                <div class="supplier-progress-tooltip2-left">零配件总额</div>
                <div class="supplier-progress-tooltip2-right">{{ item.sumAmount || '--' }}</div>
              </div>
              <!-- 多行信息<br/>第二行信息 -->
            </div>
            <div
              class="situation-box-progress-scale"
              :style="{ width: (item.sumAmountRatio || 0) + '%' }"
            ></div>
          </el-tooltip>
          <div class="situation-box-progress-ab">{{ item.sumAmount || '--' }}</div>
        </div>
        <!-- <div class="situation-box-right">
          <span>{{ item.value }}</span
          >件
        </div> -->
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      situationoptions: [
        {
          caseRate: 20,
          estimateAmount: 20,
          label: "测试",
          value: 20,
        },
      ],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
    };
  },
  created() {
    this.$nextTick(() => {});
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.scheme-box {
  padding: 1%;
  height: 82%;

  .situation {
    position: relative;
    height: 55%;
    overflow: auto;
    padding: 8% 3% 3%;
    &-box {
      display: flex;
      align-items: center;
      color: #fff;
      margin-top: 1.6%;
      padding: 0 2%;
      &-left {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 1.6rem;
        font-size: 0.14rem;
        margin-left: 0.1rem;
      }
      &-progress {
        position: relative;
        flex: 1;
        height: 0.18rem;
        background-color: rgba(13, 82, 122, 1);
        margin: 0 4px;
        border-radius: 0.09rem;
        overflow: hidden;
        &-scale {
          height: 0.18rem;
          background: linear-gradient(90deg, #0076e3, #18a9f6);
          border-radius: 0.09rem;
        }
        &-ab {
          position: absolute;
          top: 50%;
          left: 0.12rem;
          font-weight: bold;
          font-size: 0.13rem;
          color: #ffffff;
          transform: translateY(-50%);
        }
      }
      &-right {
        width: 0.88rem;
        font-size: 0.1rem;
        text-align: center;
        span {
          font-family: "ZhanKuQingKeHuangYouTi";
          margin-right: 2px;
          font-size: 0.16rem;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.supplier-progress-item {
  background-color: #fff !important;
}
.supplier-progress-tooltip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
  &-left {
    margin-right: 30px;
  }
  &-right {
  }
}
.supplier-progress-tooltip2 {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  &-left {
    margin-right: 30px;
  }
  &-right {
  }
}
</style>