<template>
  <div class="scale-box">
    <div class="scale-box-statistics">
      <div class="scale-box-statistics-item">
        <img
          src="@/assets/images/bigScreen/products/policyNumIcon.png"
          class="scale-box-statistics-item-icon"
          alt=""
          srcset=""
        />
        <div class="scale-box-statistics-item-right">
          <div class="scale-box-statistics-item-right-text">保费金额</div>
          <div class="scale-box-statistics-item-right-num2">
            {{ options.premiumAmount || "--" }}
          </div>
        </div>
      </div>
      <div class="scale-box-statistics-item">
        <img
          src="@/assets/images/bigScreen/products/equipmentNumIcon.png"
          class="scale-box-statistics-item-icon"
          alt=""
          srcset=""
        />
        <div class="scale-box-statistics-item-right">
          <div class="scale-box-statistics-item-right-text">设备数量</div>
          <div class="scale-box-statistics-item-right-num">
            {{ options.deviceNum || "--" }}
          </div>
        </div>
      </div>
    </div>
    <div class="condition">
      <div class="condition-type">
        <div class="condition-type-item" :class="{'condition-type-current' : tonnagePremiumType == 1}" @click="tonnagePremiumType = 1">吨位占比</div>
        <div class="condition-type-item" :class="{'condition-type-current' : tonnagePremiumType == 2}" @click="tonnagePremiumType = 2">保费占比</div>
      </div>
      <div class="condition-date">
        <el-select
          v-model="tonnagePremiumDate"
          class="popper-style"
          placeholder="请选择"
        >
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="scale-box-charts" ref="service-bar">
      <Chart style="height: 100%" ref="lineBox" :option="pieOptions" />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  data() {
    return {
      pieOptions: {},
      tonnagePremiumDate: '2024',
      tonnagePremiumType: 1,
      timeOptions: [
        {
          value: "2024",
          label: "2024年",
        },
        {
          value: "2023",
          label: "2023年",
        },
        {
          value: "2022",
          label: "2022年",
        },
        {
          value: "2021",
          label: "2021年",
        },
        // {
        //   value: "",
        //   label: "全部",
        // },
      ],
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.initCharts(v.tonnagePremiumProportionList || []);
        });
      },
      deep: true,
      immediate: true,
    },
    tonnagePremiumDate: {
      handler(v) {
        this.$emit('changeCondition', {
          tonnagePremiumDate: v,
          tonnagePremiumType: this.tonnagePremiumType
        })
      },
    },
    tonnagePremiumType: {
      handler(v) {
        this.$emit('changeCondition', {
          tonnagePremiumType: v,
          tonnagePremiumDate: this.tonnagePremiumDate
        })
      },
    }
  },
  created() {
    // this.init()
  },
  methods: {
    init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
      this.$nextTick(() => {
        this.initCharts();
      });
      // })
    },
    getSize(res, type) {
      const clientWidth = this.$refs["service-bar"].offsetWidth;
      const clientHeight = this.$refs["service-bar"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(data = []) {
      var scale = this.getSize(0.3, 2);
      var echartData = data.map((val) => ({
        name: val.deviceType,
        value: val.proportion,
      }));
      var rich = {
        yellow: {
          color: "#ffc72b",
          fontSize: scale * 2.6,
          fontFamily: "ZhanKuQingKeHuangYouTi",
          align: "center",
        },
        total: {
          color: "#ffc72b",
          fontSize: scale * 2.2,
          align: "center",
        },
        white: {
          color: "#fff",
          align: "center",
          fontSize: scale * 2.2,
          padding: [1, 0],
        },
        blue: {
          color: "#49dff0",
          fontSize: 2.2 * scale,
          align: "center",
        },
        hr: {
          //  borderColor: '#0b5263',
          backgroundColor: "t",
          borderRadius: 3,
          width: 2,
          height: 2,
          padding: [4, 4, 0, -12],
        },
      };
      this.pieOptions = {
        legend: {
          top: "center",
          type: "scroll",
          width: "10%",
          textStyle: {
            color: "#fff",
            fontSize: scale * 2.2,
          },
          orient: "vertical",
          itemWidth: scale * 1.2,
          itemHeight: scale * 1.2,
          right: scale * 1.2,
          textStyle: {
            rich: {
              name: {
                width: scale * 2.2,
                color: "#fff",
              },
            },
          },
          formatter: function (params) {
            return `{name|${params}}`;
          },
          data: echartData.map((val) => val.name),
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["0%", "66%"],
            center: ["35%", "50%"],
            color: [
              "#116ec9",
              "#770bd8",
              "#d0c512",
              "#1caddb",
              "#128aff",
              "#6f81da",
              "#00ffb4",
            ],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "outside",
                  color: "#ddd",
                  formatter: function (params, ticket, callback) {
                    return (
                      "{white|" +
                      params.name +
                      "}\n{hr|}\n{yellow|" +
                      params.value +
                      "/" +
                      params.percent +
                      "% }"
                    );
                  },
                  rich: rich,
                },
                labelLine: {
                  show: true,
                  type: "dashed",
                  length: scale * 2,
                  length2: scale * 3,
                  color: "#00ffff",
                },
              },
            },
            data: echartData,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.scale-box {
  padding: 2%;
  height: 88%;
  &-statistics {
    height: 33%;
    max-width: 100%;
    display: flex;
    align-items: center;
    &-item {
      height: 100%;
      max-width: 50%;
      width: 50%;
      display: flex;
      align-items: center;
      &-icon {
        height: 80%;
      }
      &-right {
        height: 50%;
        margin-top: 6%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &-text {
          font-weight: 500;
          font-size: 0.16rem;
          color: #ffffff;
        }
        &-num {
          font-family: "ZhanKuQingKeHuangYouTi";
          font-size: 0.2rem;
          color: #90ffad;
        }
        &-num2 {
          font-family: "ZhanKuQingKeHuangYouTi";
          font-size: 0.2rem;
          color: #479ffe;
        }
      }
    }
  }
  &-charts {
    height: calc(67% - 0.32rem);
  }
  .condition {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1%;
    &-type {
      display: flex;
      align-items: center;
      &-item {
        width: 0.7rem;
        height: 0.32rem;
        line-height: 0.32rem;
        text-align: center;
        font-size: 0.14rem;
        cursor: pointer;
        color: #ffffff;
        margin-right: 0.04rem;
        border: 0.01rem solid #1d80da;
      }
      &-current {
        background: rgba(8,148,255,0.3);
      }
    }
    &-date {
      height: 100% !important;
    }
  }
}
</style>
<style lang="scss">
.condition-date {
  
.popper-style {
  height: 0.32rem !important;
}
}
</style>