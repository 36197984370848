<template>
  <div class="exhibition">
    <img src="@/assets/images/bigScreen/newMechanical/carBooth.png" class="booth" alt="" srcset="">
    <!-- <img src="@/assets/images/bigScreen/newMechanical/carLamplight.png" class="lamplight" alt="" srcset=""> -->
    <img v-if="deviceImage" :src="deviceImage" alt="" class="car" srcset="">
    <img v-else src="@/assets/images/bigScreen/newMechanical/crane.png" alt="" class="car" srcset="">
  </div>
</template>

<script>
export default {
  props: {
    deviceImage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.exhibition {
  height: 100%;
  position: relative;
  .booth {
    position: absolute;
    bottom: 0.1rem;
    left: 50%;
    transform: translateX(-50%);
    height: 64%;
    z-index: 1;
  }
  .lamplight {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    height: 48%;
    z-index: 2;
  }
  .car {
    position: absolute;
    bottom: 24%;
    left: 50%;
    transform: translateX(-50%);
    height: 74%;
    z-index: 3;
  }
}
</style>