<template>
  <div id="BigScreenDisplay"
    ref="BigScreenDisplay"
    class="data-center">
    <!-- 布局头部 -->
    <div class="header" :class="{'bg1':header1,'bg4':header4,'bg4':header5}">
      <!-- :class="{'bg1':header1,'bg1':header2,'bg1':header3}" -->
      <div class="path-left">
         <img src="../../assets/images/bigScreen/equipment/goHome.png" v-if="!header4" @click="handleGoPath('BigScreenDisplay')" class="go-home" alt="" srcset="">
         <img src="../../assets/images/bigScreen/products/BigScreenDisplayBack.png" @click="handleBackNavi" style="height:0.3rem" alt="" srcset="">
          <!-- @click="handleGoPath('BigScreenDisplay')" -->
      </div>
      <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{weatherArea}}</span>
        <img class="header_weather"
          :src="weatherImg"
          alt="">
        <span style="margin-left: 0.5em">{{weatherText}}</span>
      </div>
      <span style="flex:2"></span>
      <span class="time-pane">
        {{dateText}}
        <span style="margin: 0 .5em">{{dayNum}}</span>
        {{timeStr}}
      </span>
      <div class="path-left">
        <!-- <div style="height:0.21rem;width:1px;background: rgba(255,255,255,.5);margin-right:0.2rem"></div>
         <img src="../../assets/images/bigScreen/products/BigScreenDisplayRefresh.png" style="height:0.3rem" @click="handleGoPath('BigScreenDisplayMechanical')" alt="" srcset=""> -->
        <div class="path-left navi-b">
          <img
            src="../../assets/images/bigScreen/newMechanical/lookMechanical.png"
            style="height: 0.26rem; margin-right: 0.08rem"
            class="navi-b-i1"
            @click="handleBackNavi"
            alt=""
            srcset=""
          />
          <img
            src="../../assets/images/bigScreen/newMechanical/naviMechanical.png"
            style="height: 0.26rem; margin-right: 0.08rem"
            class="navi-b-i2"
            @click="handleBackNavi"
            alt=""
            srcset=""
          />
          <span
            style="font-weight: 500; font-size: 0.18rem;"
            @click="handleBackNavi"
            >保险档案</span
          >
        </div>
         <!-- <span style="font-weight: 500;font-size: 0.18rem;color: #9DFEFF;" @click="handleBackNavi">保险档案</span> -->
         <img src="../../assets/images/bigScreen/equipment/enlarge.png" class="go-home" style="margin-left:0.3rem;margin-right:0" @click="handleClick" alt="" srcset="">
      </div>
      <!-- <Fullscreen el="#BigScreenDisplay"
        activeName="icon-quanping11"
        closeName="icon-tuichuquanping"
        class="full-pane" /> -->
    </div>

    <!-- 头部类型 -->
    <div class="car-type">
      <div class="car-type-item" :class="{ 'car-type-item-current': deviceTypeIndex == index }" @click="deviceTypeIndex = index" v-for="item,index in deviceType" :key="index">{{ item.dictName }}</div>
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <div class="main">
        <div class="above">
          <div class="left-box main">
            <div class="bfgm-box" v-loading="loading4">
              <div style="height:10%" class="hold-box">
              </div>
              <scale :options="bfgmData" @changeCondition="handleChangeCondition" />
            </div>
            <div class="bfqs-box" v-loading="loading4">
              <div style="height:13%" class="hold-box">
                <el-select
                  v-model="diggingYear"
                  class="popper-style"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in timeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <policyTendency :options="bfqsData" />
            </div>
            <div class="xz-box" v-loading="loading4">
              <div style="height:13%" class="hold-box">
              </div>
              <insurance :options="xzData" />
            </div>
          </div>
          <div class="centre-box main">
            <div class="left-box clzs-box">
              <carExhibition :deviceImage="deviceType && deviceType[deviceTypeIndex] ? deviceType[deviceTypeIndex].url : ''" />
            </div>
            <div class="centre-box map-box" v-loading="loading3">
              <ModalMap ref="ModalMap"
                @mapChang="handleMapChang"
                :mapName="mapName"
                @backToUp="mapBackToUp"
                :options="mapOption"
                :productTypes="productTypes"
                :equipmentData="equipmentData" />
            </div>
          </div>
          <div class="right-box main">
            <div class="bg-box fblx-box" v-loading="loading1">
              <div style="height:8.2%"
                class="hold-box">
                <!-- <div class="hold-box-text" @click="handleGoPath('/BigScrennDisplayService')">详情 > ></div> -->
              </div>
              <distribution :options="fblxData" @changeClaimService="(num)=> claimNum = num" />
            </div>
            <div class="bg-box yjbjqk-box" v-loading="loading2">
              <div style="height:12%"
                class="hold-box">
                <!-- <div class="hold-box-text" @click="handleGoPath('/BigScrennDisplayService')">详情 > ></div> -->
              </div>
              <alarm :options="yjbjqkData" />
            </div>
            <div class="bg-box zylpjgys-box" v-loading="loading1">
              <div style="height:18%"
                class="hold-box">
                <!-- <div class="hold-box-text" @click="handleGoPath('/BigScreenDisplayRisk')">详情 > ></div> -->
              </div>
              <supplier :options="zylpjgysData" />
            </div>
          </div>
        </div>
        <!-- <div class="underneath">
          <div class="underneath-left">
            <div class="left-box cpfa-box">
              <div style="height:15%"
                class="hold-box">
                <div class="hold-box-text" @click="handleGoPath('/BigScreenDisplayPolicy')">详情 > ></div>
              </div>
              <scheme ref="scheme" />
            </div>
            <div class="left-box cbbs-box">
              <div style="height:15%"></div>
              <acceptance ref="user-box" />
            </div>
          </div>
          <div class="right-box pfajqk-box">
            <div style="height:15%"></div>
            <zeroIndemnity />
          </div>
        </div> -->
      </div>
    </div>

  </div>
</template>
<script>
import * as echarts from 'echarts';
import ModalRealInfo from "./ModalRealInfo";
import ModalPieCharts from "./ModalPieCharts";
import ModalMap from "./components/newMechanical/ModalMap";
// import ModalVideo from "./ModalVideo";
import alarmAnalyze from "./components/alarmAnalyze";
import ModalTable from "./ModalTable";
import ModalSemicircle from "./ModalSemicircle";
import ModalLineCharts from "./ModalLineCharts";
import ModalBarCharts from "./ModalBarCharts";
import Fullscreen from "@/components/Icon/FullScreen";
import ModelBg from './components/ModelBg';
import { weatherInfo, amapGetIp } from "@/api/weather";
import { getWeek, getTimes } from "@/utils/getDate";
import { workStatistics, oilStatistics, oilTotal, workTotal, largeScreenSecurityAlarm, getLatLon, largeScreenInterfice } from "@/api/getData";
import dayjs from "dayjs";
import { filterAliMapData, filterMapData } from "@/components/CenterMap/mapUtils/index";
import chinaLi from "@/components/CenterMap/mapJson/chinaMap.json";
import policyTendency from '@/views/BigScreenDisplay/components/newMechanical/policyTendency';
import insurance from '@/views/BigScreenDisplay/components/newMechanical/insurance';
import scale from '@/views/BigScreenDisplay/components/newMechanical/scale';
import carExhibition from '@/views/BigScreenDisplay/components/newMechanical/carExhibition';
import distribution from '@/views/BigScreenDisplay/components/newMechanical/distribution';
import alarm from '@/views/BigScreenDisplay/components/newMechanical/alarm';
import supplier from '@/views/BigScreenDisplay/components/newMechanical/supplier';

import { mapMutations } from 'vuex';

import { mechanicalSpareParts, mechanicalAlarm, mechanicalMapData, mechanicalPolicyScale, mechanicalDeviceTypeImage } from '@/api/BigScreenDisplay.js';

import moment from 'dayjs';

import {
  getAreaAlarmTop,
  getManHourStatistics,
  vehicleInfoCount,
  getProductClassList,
  getRealizeAlarm,
  getProductByProvince,
  getProductTypes
} from "@/api/getHome";


export default {
  // `name`与路由保持一致
  name: 'BigScreenDisplayNewMechanical',
  components: {
    ModalRealInfo,
    ModalPieCharts,
    ModalMap,
    // ModalVideo,
    alarmAnalyze,
    ModalTable,
    ModalSemicircle,
    ModalLineCharts,
    ModalBarCharts,
    Fullscreen,
    ModelBg,
    insurance,

    // --------------------------------------------------------
    policyTendency,
    scale,
    carExhibition,
    distribution,
    alarm,
    supplier
  },
  watch: {
    diggingYear: {
      handler(v) {
        this.interfaceData.lineChartDate = v;
        this.getMechanicalPolicyScale();
      },
    },
    deviceTypeIndex: {
      handler(v) {
        this.init();
      },
    }
  },
  data() {
    // this.intervalId = null;
    return {
      header1: true,
      header2: false,
      header3: false,
      header4: false,
      header5: false,
      timeStr: '', // 时间
      timer: null, // 时间定时器
      pieOption: {},//饼图
      mapOption: [], // 地图数据
      productTypes: [], // 种类总数
      roseOption: [], // 车型分类
      // 实时警报表头配置;
      visualData: [
        { label: "车牌", key: "plate" },
        { label: "所属组织", key: "groupName" },
        { label: "报警类型", key: "alarmTypeChn" },
        { label: "时间", key: "alarmTime" },
      ],
      visualDataInfo: {},//实时警报数据
      visualMediaDataInfo: [],

      loading: false,//加载
      rankOption: [],//区域报警排行榜
      lineOption: [],// 工时统计数据
      timeType: 0,//工时统计查询类型 0：周，1：月，2：年（默认查询周）
      infoCountOption: {
        acticeRate: 0
      }, //实时统计
      oilOptions: {},//油耗统计
      workOptions: {},//工时统计
      provinceName: '', // 当前点击的地图省份 默认不传查全国数据
      weatherText: '', //天气说明
      weatherImg: '', //天气图标
      weatherArea: '', //天气地址
      vehicleTotal: 0,
      fullscreenchange: null,
      visibilitychange: null,
      getData: {    //工时参数
        pageNumber: 1,
        pageSize: 10,
        vehicleIds: [],
        startTime: getWeek()[1],
        endTime: getWeek()[2],
        groupIds: [],
        intervalMark: 1,
        deviceVehicleIds: [],
        productVehicleIds: [],
        brandVehicleIds: [],
        flag: 1, // 新增-后端数据缓存标志
      },
      realTimeAlarm: 'alarmVideo',
      oilData: {
        vehicleIds: [],
        viewType: "D",
        dateStr: '',
        flag: 5,
        startTime: moment(new Date()).format("YYYY-MM-DD"),
        endTime: moment(new Date()).add(1, 'days').format("YYYY-MM-DD"),
        groupIds: [],
        exportExcel: false,
      },
      mapName: 'china',
      mapAdcode: 'china',
      mapListData: [],
      backLoading: false,
      mapHierarchyList: [],
      intervalId: null,

      // -------------
      dayNum: '',
      dateText: '',
      jszxData: {},
      equipmentData: {},
      userNum: 0,
      policyNum: 0,
      claimNum: 0,
      equipmentNum: 0,

      fblxData: {},
      zylpjgysData: [],
      yjbjqkData: {},
      xzData: [],
      bfqsData: [],
      bfgmData: {},
      diggingYear: '2024',
      timeOptions: [
        {
          value: "2024",
          label: "2024年",
        },
        {
          value: "2023",
          label: "2023年",
        },
        {
          value: "2022",
          label: "2022年",
        },
        {
          value: "2021",
          label: "2021年",
        },
      ],
      interfaceData: {
        tonnagePremiumType: 1,
      },
      deviceType: [],
      deviceTypeIndex: 0,
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
    };
  },
  created() {
    const { name } = this.$store.state.userInfo;
    this.mapListData = chinaLi;
    if (name == '华北利星行') {
      this.header1 = false,
        this.header2 = true;
    } else if (name == "广东伟信市政") {
      this.header3 = true;
    };
    this.getMechanicalDeviceTypeImage();
  },
  // mounted
  activated() {
    let that = this;
    this.deviceType && this.deviceType.length ? this.init() : '';
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ['日', '一', '二', '三', '四', '五', '六'];
    this.dayNum = '周' + week[datas];
    this.dateText = moment().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = moment(new Date()).format("HH:mm:ss");
      this.timeStr = `${ today }`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.fullscreenchange = true;
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // destroyed
  deactivated() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },

  methods: {
    async init() {
      this.$nextTick(() => {
        // this.$refs['equipment'].init();
        // this.$refs['service-model'].init();
        // this.$refs['policy'].init();
        // this.$refs['risk'].init();
        // this.$refs['user-box'].init();
        this.getMechanicalSpareParts();
      });
    },
    handleFullscreenchange() {
      this.init();
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handlePolling() {
      this.intervalId = setTimeout(async () => {
        await this.init();
        // this.$refs.ModalMap.mapChange()
        clearTimeout(this.intervalId);
        if (this.fullscreenchange) {
          this.handlePolling();
        }
      }, 1000 * 60);
    },
    handleVest(v) {
       this.equipmentNum = Number(v.allTotal);
      this.equipmentData = v;
    },
    // 获取产品种类分布情况 - 地图
    async getProductTyps(data = []) {
      // const result = await getProductByProvince({ provinceName: this.provinceName });
      //抽取数据
      if (this.mapName === 'china') {
        let dataList = await filterAliMapData(chinaLi, '', data);
        this.mapOption = dataList.filter(val => val.value && val.value > 0);
      } else {
        const specialList = ['北京', '上海', '重庆', '天津', '北京市', '上海市', '重庆市', '天津市'];
        let ddd = await filterAliMapData(this.mapListData, ((this.provinceName && !(this.mapName.indexOf('省') > -1 || this.mapName.indexOf('自治区') > -1 || this.mapName.indexOf('特别') > -1)) ? this.provinceName + ',' + this.mapName : specialList.includes(this.mapName) ? this.mapName + ',市辖区' : this.mapName));
        this.mapOption = ddd.filter(val => val.value && val.value > 0);
      }
    },
    getFullScreenEl() {
      const { el } = this;
      // return (isElement('el')
      //   ? el
      //   : document.querySelector(el)) || document.documentElement;
      return this.$refs['BigScreenDisplay'];
    },
    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },
    handleClick() {
      this.SetFullscreen()
      // const isFullscreen = !this.isFullscreen;

      // if (isFullscreen) { // 进入全屏
      //   this.requestFullscreen();
      // } else {
      //   // 退出全屏
      //   this.exitFullscreen();
      // }

    },

    async handleGetWeather() {
      let cityData = await amapGetIp({ key: '60f85fa7d1c353b0a2ffb92083e365f0' });
      let imgList = [
        'https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png', //小雨
        'https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png', //大雨
        'https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png',  // 雷雨
        'https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png', //多云
        'https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png', //雪
        'https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png', // 晴
      ];
      //  {city: cityData.city}
      weatherInfo({ key: '60f85fa7d1c353b0a2ffb92083e365f0', city: cityData.adcode }).then(res => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText = weatherData.temperature + '°' + ' ' + weatherData.weather;
        if (weatherData.weather.indexOf('雷') > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf('大雨') > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf('雨') > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf('雪') > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf('晴') > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf('云') > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },
    async handleMapChang(data) {
      if (this.backLoading) {
        return false;
      }
      this.backLoading = true;
      if (this.mapName === 'china') {
        let initData = await filterMapData(this.mapListData, "", true);
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName
        };
        // this.mapHierarchyList.push({
        //   mapListData: JSON.parse(JSON.stringify(this.mapListData)),
        //   adcode: this.mapAdcode,
        //   mapName: this.mapName
        // })
        const filData = initData.filter(val => (val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1))[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          this.mapOption = ddd.filter(val => val.value && val.value > 0);
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
        // this.mapHierarchyList.push(pushData)
        // this.mapOption = ddd.filter(val=> val.value && val.value > 0)
      } else {
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName
        };
        const filData = this.mapListData.filter(val => (val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1))[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          this.mapOption = ddd.filter(val => val.value && val.value > 0);
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
      }
      this.backLoading = false;
    },

    async downloadedMaps(adcode = '100000', name) {
      let mapUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${ adcode }_full.json`;
      let resultData = await getLatLon({ url: mapUrl });
      if (!resultData.obj) {
        this.backLoading = false;
        this.$refs.ModalMap.isMapLoading = false;
        this.$message.error('暂不支持该区域缩放');
        return false;
      }
      const specialList = ['北京', '上海', '重庆', '天津', '北京市', '上海市', '重庆市', '天津市'];
      //抽取数据
      let dataList = this.data = await filterAliMapData(resultData, (this.provinceName ? this.provinceName + ',' + name : specialList.includes(name) ? name + ',市辖区' : name));
      if (name.indexOf('省') > -1 || name.indexOf('自治区') > -1 || name.indexOf('特别') > -1) {
        this.provinceName = name;
      }
      this.mapListData = JSON.parse(JSON.stringify(dataList));
      echarts.registerMap(name, resultData.obj);
      // this.mapObject = resultData.obj;
      return dataList;

    },
    async mapBackToUp() {
      const data = this.mapHierarchyList.pop();
      this.mapName = data.mapName;
      this.mapListData = data.mapListData;
      if (data.mapName === 'china') {
        this.provinceName = "";
        let dataList = await filterAliMapData(data.mapListData, '');
        this.mapOption = dataList.filter(val => val.value && val.value > 0);
      } else {
        let ddd = await filterAliMapData(data.mapListData, data.mapName);
        this.mapOption = ddd.filter(val => val.value && val.value > 0);
      }
    },
    getMechanicalSpareParts() {
      this.loading1 = true;
      this.loading2 = true;
      this.loading3 = true;
      mechanicalSpareParts({
        dictValue: this.deviceType[this.deviceTypeIndex].dictValue
      }).then(res => {
        this.loading1 = false;
        this.fblxData = {
          brand: res.data.distributionList[1] || [],
          finance: res.data.distributionList[2] || [],
          age: res.data.distributionList[3] || [],
          mechanicalValuePremiumAmountList: res.data.mechanicalValuePremiumAmountList || []
        };
        this.zylpjgysData = res.data.sparePartsList || [];
      }).catch(err => {
        this.loading1 = false;
      });
      mechanicalAlarm({
        dictValue: this.deviceType[this.deviceTypeIndex].dictValue
      }).then(res=>{
        this.loading2 = false;
        this.yjbjqkData = res.data.alarm;
      }).catch(err => {
        this.loading2 = false;
      });;
      mechanicalMapData({
        dictValue: this.deviceType[this.deviceTypeIndex].dictValue
      }).then(res=>{
        this.loading3 = false;
        this.mapOption = res.data.customDeviceByProvinceCityList.map(val=>{
          return {
            name: val.provinceName,
            value: val.count
          }
        })
      }).catch(err => {
        this.loading3 = false;
      });;
      this.getMechanicalPolicyScale();
    },
    getMechanicalPolicyScale() {
      this.loading4 = true;
      mechanicalPolicyScale({...this.interfaceData, dictValue: this.deviceType[this.deviceTypeIndex].dictValue}).then(res=>{
        this.loading4 = false;
        this.xzData = res.data.insuranceList || [];
        this.bfqsData = res.data.premiumAmountTrendList || [];
        this.bfgmData = { ...res.data.policyScale, tonnagePremiumProportionList: res.data.tonnagePremiumProportionList || [] }
      }).catch(err => {
        this.loading4 = false;
      });
    },
    getMechanicalDeviceTypeImage() {
      mechanicalDeviceTypeImage({}).then(res=> {
        this.deviceType = res.data;
        this.init();
      })
    },
    handleGoPath(path) {
      this.$router.push({
        path
      });
    },
    handleBackNavi() {
      this.$router.go(-1);
    },
    handleChangeCondition(e) {
      this.interfaceData = {...this.interfaceData, ...e};
      this.getMechanicalPolicyScale();
    },
    ...mapMutations(['SetFullscreen'])
  }
}
</script>


<style lang="scss" scoped>
@import './DataCenterStyle.scss';
@import './newMechanicalStyle.scss';
.bg1 {
  background-image: url('../../assets/images/bigScreen/home/titleBg.png');
}
.bg2 {
  background-image: url('../../assets/images/bigScreen/top3.png');
}
.bg3 {
  background-image: url('../../assets/images/bigScreen/top2.png');
}
.bg4 {
  background-image: url('../../assets/images/bigScreen/zgsyBg.png');
}
.pattern-img {
  position: absolute;
  top: 17px;
  cursor: pointer;
  right: 35px;
  width: 17px;
  z-index: 99;
}
.navi-b {
  font-weight: 500;
  font-size: 0.18rem;
  color: #9DFEFF;
  &-i1 {
    display: block;
  }
  &-i2 {
    display: none;
  }
}
.navi-b:hover {
  color: #00A8FF;
  .navi-b-i1 {
    display: none !important;
  }
  .navi-b-i2 {
    display: block !important;
  }
}
</style>
<style lang="scss">
.popper-style {
  width: 0.88rem;
  height: 60% !important;
  margin-top: 1%;
  .el-input {
    height: 100% !important;
  }
  input {
    color: #ffffff;
    height: 100% !important;
    border: 0.01rem solid #0267cb;
    background: rgba(157, 254, 255, 0.2);
    padding-left: 0.04rem;
    padding-right: 0.04rem;
  }
  .el-input__inner {
    height: 100% !important;
    line-height: initial;
    padding: 0.02rem 0.22rem 0.02rem  0.1rem;
    // padding-right: 0.22rem;
    font-size: 0.12rem;
  }
  .el-input__icon {
    height: 100% !important;
    line-height: 100% !important;
  }
  .el-input__suffix {
    right: 0;
  }
  .el-icon-arrow-up:before {
    font-size: 0.12rem;
  }
}
</style>